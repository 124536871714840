<template>
  <Layout>
    <WithLocation
      v-slot="{ isLocationLoading, theLocation, theFeaturedImage }"
      :slug="locSlug"
    >
      <transition name="fade" mode="out-in">
        <BaseLoader v-if="isLocationLoading" bg-color="#cfebdf" color="#FFF"
          >Loading...</BaseLoader
        >
        <div v-else-if="!theLocation" class="not-found">
          <BasePageSection bg-color="#cfebdf">
            <VLayout justify-center>
              <VFlex xs6>
                <h3 class="r-headline"
                  >Sorry, but we couldn't find that location. Pick one below or
                  <BaseButton
                    flat
                    link
                    small
                    color="green darken-2"
                    to="/contact"
                    >get in touch</BaseButton
                  >
                  if you need assistance.</h3
                >
              </VFlex>
            </VLayout>
          </BasePageSection>
          <BaseThemeComponent name="SectionLocations" />
        </div>
        <div v-else class="single-location">
          <BasePageSection bg-color="#cfebdf" grid-list-xl>
            <VLayout row justify-center wrap>
              <VFlex
                v-if="theFeaturedImage"
                lg5
                sm6
                order-sm1
                xs12
                order-xs2
                hidden-xs-only
              >
                <img
                  v-if="$mq === 'xl'"
                  :src="theFeaturedImage"
                  :alt="`${getSiteTitle} - ${theLocation.title}`"
                />
                <div
                  v-else
                  class="featured-image"
                  :style="[
                    { 'background-image': `url('${theFeaturedImage}')` },
                  ]"
                  role="img"
                  :aria-label="
                    `${getSiteTitle} - ${theLocation.title} Main Image`
                  "
                ></div>
              </VFlex>
              <!-- TODO (ESS) : refactor this attr sitch -->
              <VFlex
                :lg5="theFeaturedImage"
                :sm6="theFeaturedImage"
                :lg8="!theFeaturedImage"
                :sm10="!theFeaturedImage"
                order-sm1
                xs12
                order-xs1
              >
                <SingleLocationContactInfo
                  v-if="theLocation.contactinfo"
                  :location="theLocation"
                />
              </VFlex>
            </VLayout>
          </BasePageSection>
          <BasePageSection
            v-if="theLocation.status !== 'coming_soon'"
            id="menu"
            bg-color="#FDF9EC"
            py-2
            fluid
          >
            <h3 class="header-1 text-xs-center d-block mb-4"
              >The {{ theLocation.title }} Menu</h3
            >
            <!-- TODO (ESS) : Create default menu block (1 col? 2 col?) -->
            <DailyBeetMenu :location="theLocation" />
          </BasePageSection>
          <BaseThemeComponent
            name="SectionLocations"
            title="Our Other Locations"
            :filter="excludeThisLocation"
          />
        </div>
      </transition>
    </WithLocation>
    <BaseMenuComponent name="MenuSnackbar" />
  </Layout>
</template>

<script>
import Layout from '@utils/get-layout'

import SingleLocationContactInfo from '@components/SingleLocationContactInfo'
import { mapGetters } from 'vuex'

export default {
  name: 'SingleLocation',
  // page() {
  //   return {
  //     title: this.theLocation.title || 'Our Location',
  //     meta: [
  //       {
  //         name: 'description',
  //         content:
  //           this.theLocation.address ||
  //           this.theLocation.coming_soon ||
  //           'One of our locations',
  //       },
  //     ],
  //   }
  // },
  components: {
    Layout,
    SingleLocationContactInfo,
  },
  props: {
    locSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getSiteTitle']),
  },
  methods: {
    excludeThisLocation(loc) {
      return loc.slug !== this.$route.params.locSlug
    }, // excludeThisLocation
  },
}
</script>

<style lang="scss">
@import '@design';

.single-location--header {
  @media only screen and (max-width: 321px) {
    .v-btn {
      height: 38px;
      padding: 10px !important;
    }
  }
}

.single-location {
  .featured-image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
}
</style>
